import React from "react";
import PropTypes from "prop-types";

import Accordion from "@keeex/js-react-components/lib/accordion.js";

import renderBasic from "./parts/complex/basic.js";
import renderPhotoproof from "./parts/complex/photoproof.js";
import renderTimestamp from "./parts/complex/timestamp.js";

/**
 * Props:
 * - identityProvider
 * - result: kxresult
 * - expanded
 * - onReset
 * - onExpand
 */
export default class Complex extends React.Component {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
  }

  componentDidMount() {
    if (this._ref.current) {
      this._ref.current.scrollIntoView({behavior: "smooth", block: "start"});
    }
  }

  render() {
    return <div className="complexResult box" ref={this._ref}>
      <Accordion default={0}>
        {renderBasic(
          this.props.result,
          this.props.identityProvider,
          this.props.staticPath
        )}
        {renderPhotoproof(
          this.props.result)}
        {renderTimestamp(
          this.props.network,
          this.props.timestampInfo,
          this.props.timestampError)}
      </Accordion>
    </div>;
  }
}
Complex.propTypes = {
  result: PropTypes.object,
  identityProvider: PropTypes.func,
  timestampInfo: PropTypes.object,
  timestampError: PropTypes.object,
  network: PropTypes.string,
  staticPath: PropTypes.string,
};
