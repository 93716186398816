/*eslint-env node */
import React from "react";
import PropTypes from "prop-types";
import {toSvg} from "jdenticon";
import {Localize, _l} from "./localize.js";
import PublicKey from "./publickey.js";
import StaticCtx from "./context/static.js";
import { Tooltip } from "./tooltip.js";
import {searchProviders} from "./searchprovider.js";

/**
 * Props:
 * - publicKey
 * - valid
 * - signature
 * - short
 * - identityProvider
 */
export default class Identity extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({
      name: undefined,
      logo: undefined,
      url: undefined,
      loading: this.props.identityProvider !== null,
    }, this.state || {});
  }

  _getIdentity() {
    if (this.props.identityProvider && this.props.publicKey) {
      return this.props.identityProvider(this.props.publicKey);
    }
    return Promise.resolve(null);
  }

  componentDidMount() {
    this._getIdentity()
      .then(res => {
        if (!res) {
          return;
        }
        this.setState({
          name: res.name,
          logo: res.logo,
          url: res.url,
        });
      }).catch(() => {})
      .then(() => this.setState({loading: false}));
  }

  /** Wrap the provided components in the entity URL */
  _wrapInEntityURL(elements, key) {
    if (this.state.url) {
      return <a
        key={key}
        target="_blank"
        rel="noopener noreferrer"
        href={this.state.url}>
        {elements}
      </a>;
    }
    return elements;
  }

  _getSearchElements(staticPath, short) {
    if (searchProviders && Object.keys(searchProviders).length > 0) {
      const searchElems = searchProviders.map(provider =>
        <a
          key={provider.name}
          target="_blank"
          rel="noopener noreferrer"
          href={provider.url.replace("%QUERY%", this.props.publicKey)}>
          <img
            src={`${staticPath}/img/${provider.icon}`}
            className="search-icon" />
          {short ? null : provider.name}
        </a>);
      return <span key="searchGroup" className="search-group">
        {short
          ? null
          : <>
            <Localize>Search this identity with</Localize>
            {/* eslint-disable-next-line max-len */}
            <Tooltip text={_l("This public key may have been claimed by its owner who may have published it on his website, Twitter, Facebook or LinkedIn for example.")} />
          </>
        }
        {searchElems}
      </span>;
    }
    return null;
  }

  _getShortElements(staticPath) {
    if (!this.props.publicKey) {
      return <span className="identityKey">
        <Localize>Invalid public key</Localize>
      </span>;
    }
    const elements = [];
    if (this.state.name && this.state.name.length) {
      elements.push(this._wrapInEntityURL(<span
        className="identityName">
        {this.state.name}
      </span>, "key"));
    } else {
      elements.push(
        <PublicKey
          key="key"
          publicKey={this.props.publicKey} />);
    }
    if (!this.state.name || this.state.name.length == 0) {
      elements.push(this._getSearchElements(staticPath, true));
    }
    if (this.state.logo && this.state.logo.length) {
      elements.push(this._wrapInEntityURL(<img
        className="identityLogo"
        src={this.state.logo} />,
      "logo"));
    } else {
      elements.push(<span
        key="logo"
        className="identityLogo"
        dangerouslySetInnerHTML={{
          __html: toSvg(this.props.publicKey, 40),
        }} />);
    }
    return elements;
  }

  _signatureCheckURL() {
    const publicKey = this.props.publicKey;
    const topic = this.props.idx;
    const signature = this.props.signature;
    const queryBuilder = new URLSearchParams();
    queryBuilder.append("adr", publicKey);
    queryBuilder.append("msg", topic);
    queryBuilder.append("sig", signature);
    const serviceURL = "https://services.keeex.me/signature-verification/";
    return `${serviceURL}?${queryBuilder.toString()}`;
  }

  _getLongElements(staticPath) {
    if (!this.props.publicKey) {
      return <span className="identityKey">
        <Localize>Invalid public key</Localize>
      </span>;
    }
    const elements = [];
    if (this.state.logo && this.state.logo.length) {
      elements.push(this._wrapInEntityURL(<img
        className="identityLogo is-pulled-right"
        src={this.state.logo} />,
      "logo"));
    } else {
      elements.push(<span
        key="logo"
        className="identityLogo is-pulled-right"
        dangerouslySetInnerHTML={{
          __html: toSvg(this.props.publicKey, 40),
        }} />);
    }
    if (this.state.name && this.state.name.length) {
      elements.push(<span
        key="name"
        className="identityName">
        {this._wrapInEntityURL(this.state.name)} - <PublicKey
          publicKey={this.props.publicKey} />
      </span>);
    } else {
      elements.push(<PublicKey
        key="key"
        publicKey={this.props.publicKey} />);
    }
    elements.push(this._getSearchElements(staticPath, false));
    const signatureClass = this.state.logo
      ? ["signature", "withLogo"]
      : ["signature"];
    elements.push(<span
      key="signature"
      className={signatureClass.join(" ")}>
      <Localize>Signature :</Localize>
      {/* eslint-disable-next-line max-len */}
      <Tooltip text={_l("Result of the encryption of the file's fingerprint by the signer's private key. The signature allows anyone who knows the digital fingerprint of the file and the public key of the recipient to ensure that the document is genuine and authentic (KeeeX facilitates this verification which can also be done manually in total independence via usual cryptographic tools).")} />
      <br />
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={this._signatureCheckURL()}>
        <span className="signatureContent">{this.props.signature}</span>
      </a>
    </span>);
    return elements;
  }

  render() {
    if (this.state.loading) {
      return <p><Localize>Loading…</Localize></p>;
    }
    return <StaticCtx.Consumer>
      {staticCtx => this._renderContent(staticCtx)}
    </StaticCtx.Consumer>;
  }

  _renderContent({staticPath}) {
    const elements = this.props.short
      ? this._getShortElements(staticPath)
      : this._getLongElements(staticPath);
    const classes = ["identity"];
    classes.push(this.props.valid
      ? "valid"
      : "invalid");
    return <div className={classes.join(" ")}>
      {elements}
    </div>;
  }
}
Identity.propTypes = {
  publicKey: PropTypes.string,
  short: PropTypes.bool,
  identityProvider: PropTypes.func,
  signature: PropTypes.string,
  valid: PropTypes.bool,
  idx: PropTypes.string,
};
