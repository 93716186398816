/** Search provider, to do an Internet search about a given identity */
export const searchProviders = [
  {
    icon: "goog.svg",
    name: "Google",
    url: "https://www.google.com/search?q=%QUERY%",
  },
  {
    icon: "dax.svg",
    name: "DuckDuckGo",
    url: "https://duckduckgo.com/?q=%QUERY%",
  },
];
