import React from "react";
import {decode} from "html-entities";
import {Localize, _l} from "../../localize.js";
import {decodeUTF8} from "@keeex/js-utils/lib/string.js";
import IDX from "../../idx.js";
import Accordion from "@keeex/js-react-components/lib/accordion.js";
import { searchProviders } from "../../searchprovider.js";

import {
  SimpleSection,
  renderIdentities,
  getIDX,
  getProp,
  SystemDate,
} from "../../common.js";
import { Tooltip } from "../../tooltip.js";

const _renderIDX = (staticPath, result) => {
  const idx = getIDX(result);
  if (!idx) {
    return;
  }
  let searchElems;
  if (searchProviders && Object.keys(searchProviders).length > 0) {
    searchElems = searchProviders.map(provider =>
      <a
        key={provider.name}
        target="_blank"
        rel="noopener noreferrer"
        href={provider.url.replace("%QUERY%", idx)}>
        <img
          src={`${staticPath}/img/${provider.icon}`}
          className="search-icon" />
        {provider.name}
      </a>);
  }
  return <SimpleSection
    title={<Localize>Unique file identifier (IDX - File hash)</Localize>}
    className="fileIDX">
    <IDX
      idx={idx}
      limit={9}
      canExtend />
    {searchElems
      ? (
        <span className="search-group">
          <Localize>Search this IDX with</Localize>
          {/* eslint-disable-next-line max-len */}
          <Tooltip text={_l("Allows you to find out if the unique fingerprint of the file has been published on the Internet and indexed by a search engine.")} />
          {searchElems}
        </span>
      )
      : undefined
    }
  </SimpleSection>;
};

const _renderIdentities = (result, identityProvider) => {
  if (!result.identities
      || !result.identities.length > 0) {
    return;
  }
  return renderIdentities(
    getIDX(result),
    result.identities,
    identityProvider,
    false);
};

const cleanDescription = originalDescription => {
  if (!originalDescription) {
    return;
  }
  return decode(decodeUTF8(originalDescription));
};

const _renderDescription = result => {
  const description = cleanDescription(getProp(result, "kx.description"));
  return description
    ? <SimpleSection
      title={<Localize>Description</Localize>}
      className="fileDescription">
      <pre className="fileDescriptionContent box">
        {description.split(/<br(?: *\/)?>/)
          .map((row, id) => <React.Fragment key={id}>
            {row}<br />
          </React.Fragment>)}
      </pre>
    </SimpleSection>
    : null;
};

const _renderDate = result => {
  const kxTime = getProp(result, "kx.time");
  if (!kxTime) {
    return;
  }
  return <SystemDate
    date={kxTime}
    extended />;
};

const render = (result, identityProvider, staticPath) => {
  return <Accordion.Section
    title={<>
      <Localize>KeeeX metadata verification</Localize>
      {/* eslint-disable-next-line max-len */}
      <Tooltip position="bottom" text={_l("The KeeeX patented process allows you to tamper-proof seal proofs of trust directly into files. No need for an Internet connection to verify this information once the verification page is launched on your browser (try it in Airplane mode, you'll see!).")} />
    </>}
    className="infoRows">
    {_renderIdentities(result, identityProvider)}
    {_renderIDX(staticPath, result)}
    {_renderDescription(result)}
    {_renderDate(result)}
  </Accordion.Section>;
};

export default render;
