import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  _l,
  Localize,
  LocaleUpdate,
} from "./localize.js";

import Identity from "./identity.js";
import { Tooltip } from "./tooltip.js";

const CERTIFICATE_REF =
  "relec-toluz-podys-hofev-posav-sypim-nymol-fokob-racor-"
  + "deded-zibus-cyrad-typov-nafed-gadif-bidov-kixir";

/** A section with a label and a content.
 *
 * Props:
 * - title
 */
export class SimpleSection extends React.Component {
  render() {
    let classes = ["infoRow"];
    if (this.props.className) {
      classes = classes.concat(this.props.className.split(" "));
    }
    return <div className={classes.join(" ")}>
      <div className="infoLabel">
        {this.props.title}
      </div>
      {this.props.children}
    </div>;
  }
}
SimpleSection.propTypes = {
  title: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
};

/** Render a single identity.
 *
 * @param {number} id
 * The index of this identity in the keeexed file
 */
const renderOneIdentity = (
  idx,
  id,
  identity,
  identityProvider,
  short
) => {
  return <Identity
    key={identity.publicKey || `nokey-${id}`}
    idx={idx}
    identityProvider={identityProvider}
    valid={identity.state === "VALID"}
    short={short}
    signature={identity.signature}
    publicKey={identity.publicKey} />;
};

/** Render the short panel side list of identities */
const renderShort = (
  idx,
  identities,
  identityProvider
) => {
  const title = identities.length > 1
    ? _l("First signatory")
    : _l("Signatory");
  const tooltip = identities.length > 1
    // eslint-disable-next-line max-len
    ? _l("The digital identity of the first signatory of the file, which can be a natural person, a legal entity or a computer system. When using Bitcoin signatures, the identity of the signer can be verified by KeeeX (display of a name and/or logo) or remain pseudonymous (display of the public key).")
    // eslint-disable-next-line max-len
    : _l("The digital identity of the signatory of the file, which can be a natural person, a legal entity or a computer system. When using Bitcoin signatures, the identity of the signer can be verified by KeeeX (display of a name and/or logo) or remain pseudonymous (display of the public key).");
  return <SimpleSection
    title={<>
      <Localize>{title}</Localize>
      <Tooltip text={tooltip} />
    </>}
    className="identities">
    {renderOneIdentity(idx, 0, identities[0], identityProvider, true)}
    {identities.length > 1
      ? <Localize>See Details below for other signatories</Localize>
      : null}
  </SimpleSection>;
};

const renderLong = (
  idx,
  identities,
  identityProvider
) => {
  const title = identities.length > 1
    ? _l("Signatories")
    : _l("Signatory");
  const tooltip = identities.length > 1
    // eslint-disable-next-line max-len
    ? _l("The digital identity of the signatories of the file, which can be natural persons, legal entities or computer systems. When using Bitcoin signatures, the identity of the signatories can be verified by KeeeX (display of a name and/or logo) or remain pseudonymous (display of the public key).")
    // eslint-disable-next-line max-len
    : _l("The digital identity of the signatory of the file, which can be a natural person, a legal entity or a computer system. When using Bitcoin signatures, the identity of the signer can be verified by KeeeX (display of a name and/or logo) or remain pseudonymous (display of the public key).");
  return <SimpleSection
    title={<>
      <Localize>{title}</Localize>
      {/* eslint-disable-next-line max-len */}
      <Tooltip position="bottom" text={tooltip} />
    </>}
    className="identities">
    {identities.map((identity, id) =>
      renderOneIdentity(idx, id, identity, identityProvider, false))}
  </SimpleSection>;
};

/** Render the list of identities found in a file
 *
 * @param {Array} identities
 * @param {function} identityProvider
 * @param {boolean} short
 *
 * @returns {Node}
 */
export const renderIdentities = (
  idx,
  identities,
  identityProvider,
  short) => {
  if (!identities || identities.length == 0) {
    return;
  }
  if (short) {
    return renderShort(idx, identities, identityProvider);
  }
  return renderLong(idx, identities, identityProvider);
};

/** Check if the results are from a bitcoin certificate
 *
 * @return {Object?}
 * Return either null (if not a certificate) or an object with the following
 * fields:
 * - timestampInfo.transactionId
 * - timestampInfo.topixIdx
 * - timestampInfo.isCertificate
 */
export const getAsBitcoinCertificate = result => {
  if (!result || !result.metadata || !result.metadata.statements) {
    return null;
  }
  const isAnchorCert = result.metadata.statements.find(
    e => e.directive == "ref"
    && e.value == CERTIFICATE_REF);
  if (!isAnchorCert) {
    return null;
  }
  const transactionIdStatement = result.metadata.statements.find(
    e => e.directive == "protected"
      && e.extra
      && e.extra.transaction == "blockchain");
  const transactionId = transactionIdStatement.value.replace(/-->|<!--/g, "");
  return {
    timestampInfo: {
      transactionId,
      topicIdx: getIDX(result),
      isCertificate: true,
    },
  };
};

/** Return the main IDX from the result.
 *
 * @param {hash} results
 * Output of verifier
 *
 * @returns {string}
 */
export const getIDX = results => {
  if (!results.idxs || results.idxs.length == 0) {
    return;
  }
  for (let i = 0; i < results.idxs.length; ++i) {
    if (results.idxs[i].type === "idx") {
      return results.idxs[i].value;
    }
  }
};

/** Return the value from a prop
 *
 * @param {hash} results
 * Output of verifier
 *
 * @returns {string}
 */
export const getProp = (results, propName) => {
  if (!results.metadata
    || !results.metadata.statements) {
    return;
  }
  const stmt = results.metadata.statements;
  for (let i = 0; i < stmt.length; ++i) {
    if (stmt[i].directive != "prop") {
      continue;
    }
    if (stmt[i].name != propName) {
      continue;
    }
    return stmt[i].value;
  }
};

/** Check if a file have the given ref
 *
 * @param {hash} results
 * Output of verifier
 *
 * @param {string} ref
 *
 * @returns {boolean}
 */
export const haveRef = (results, ref) => {
  if (!results.metadata
    || !results.metadata.statements) {
    return;
  }
  const stmt = results.metadata.statements;
  for (let i = 0; i < stmt.length; ++i) {
    if (stmt[i].directive != "ref") {
      continue;
    }
    if (stmt[i].value != ref) {
      continue;
    }
    return true;
  }
  return false;
};

export class PreciseDate extends React.Component {
  render() {
    if (!this.props.tsrResult || !this.props.tsrResult.date) {
      if (!this.props.systemDate) {
        return null;
      }
      return <SystemDate
        date={this.props.systemDate}
        extended={this.props.extended} />;
    }
    return <TSRDate
      tsrData={this.props.tsrResult}
      extended={this.props.extended} />;
  }
}
PreciseDate.propTypes = {
  tsrResult: PropTypes.object,
  systemDate: PropTypes.string,
  extended: PropTypes.bool,
};

/** Display the TSR date
 *
 * Props:
 * - tsrData: from tSR
 * - extended: long date
 */
export class TSRDate extends React.Component {
  render() {
    if (!this.props.tsrData.date) {
      return <SimpleSection
        title={<Localize>Certificate time</Localize>}
        className="fileDate">
        <span>
          <Localize>Date can not be extracted from certificate</Localize>
        </span>;
      </SimpleSection>;
    }
    if (!this.props.tsrData.granted) {
      return <SimpleSection
        title={<Localize>Certificate time</Localize>}
        className="fileDate">
        <span>
          <Localize>TSR certificate was not granted</Localize>
        </span>
      </SimpleSection>;
    }
    const dateStr = this.props.extended
      ? (moment(this.props.tsrData.date).format("LL")
        + ", "
        + moment(this.props.tsrData.date).format("LTS"))
      : moment(this.props.tsrData.date).format("lll");
    const accuracyString = (this.props.tsrData.accuracy
      && this.props.extended)
      ? <Localize
        data-accuracy={this.props.tsrData.accuracy}
      > (precision: %%%accuracy%%%)</Localize>
      : "";
    return <SimpleSection
      title={<>
        <Localize>Certification date</Localize>
        {/* eslint-disable-next-line max-len */}
        <Tooltip text={_l("The date on which the IDX (fingerprint) of the file was signed by a timestamp server. This is usually the date the file was keeexed.")} />
      </>}
      className="fileDate"
      key="fileDate">
      <span className="rfcDate">{dateStr}{accuracyString}</span>
    </SimpleSection>;
  }
}
TSRDate.propTypes = {
  tsrData: PropTypes.object,
  extended: PropTypes.bool,
};
/** Display the system date from a file with a warning tooltip.
 *
 * Props:
 * - date: string from the kx.time property
 * - extended: long date
 */
export class SystemDate extends React.Component {
  render() {
    const dateStr = moment(this.props.date).format(this.props.extended
      ? "llll"
      : "lll");
    /*eslint-disable-next-line max-len */
    const tooltip = _l("This is the date reported by the system where this file was keeexed. It is provided only for informational purpose. Check the blockchain certificate or the timestamp certificate for a provable date of existence of this file.");
    const title = <LocaleUpdate>
      <span>
        <Localize>System date when keeexed</Localize> <Tooltip
          text={tooltip}
        />
      </span>
    </LocaleUpdate>;
    return <SimpleSection
      title={title}
      key="fileDate"
      className="fileDate">
      <span>{dateStr}</span>
    </SimpleSection>;
  }
}
SystemDate.propTypes = {
  date: PropTypes.string,
  extended: PropTypes.bool,
};
