import React from "react";
import PropTypes from "prop-types";
import ClipboardJS from "clipboard";
import bulmaToast from "bulma-toast";
import {_L} from "./localize.js";

let uniqueId = 0;

/** Return a unique ID (for this session) */
export const getUniqueId = () => `keeexVerifPubKey${++uniqueId}`;

export default class PublicKey extends React.Component {
  constructor(props) {
    super(props);
    this._id = getUniqueId();
  }

  componentDidMount() {
    const clipboard = new ClipboardJS(`#${this._id}`, {
      text: () => this.props.publicKey,
    });
    clipboard.on("success", () => {
      bulmaToast.toast({
        message: _L("Public key copied to clipboard"),
        type: "is-info",
        pauseOnHover: true,
      });
    });
  }

  render() {
    return <span
      id={this._id}
      className="identityKey publicKey">
      {this.props.publicKey}
    </span>;
  }
}
PublicKey.propTypes = {
  publicKey: PropTypes.string,
};
