/* eslint-disable no-console */
/*eslint-env browser */
import StatsSdk from "@keeex/simplestats-sdk";

const getReferrer = () => {
  if (parent !== window) {
    try {
      const url = new URL(document.referrer);
      return url.hostname;
    } catch (e) {
      console.error("Could not get referrer", e);
    }
  }
  return window.location.hostname;
};

try {
  const ref = getReferrer();
  console.debug("Referrer detected:" + ref);
} catch (e) {
  console.error("Could not get referrer", e);
}

export const PingTypes = {
  display: "display",
  filesProcessed: "files/processed",
  filesValid: "files/valid",
  filesNonKeeex: "files/nonkeeex",
};

const sdk = new StatsSdk("https://stat.keeex.me");

export const ping = (ctx, pingType) => {
  if (!ctx.apiKey) {
    return;
  }
  sdk.setAuthToken(ctx.apiKey);
  sdk.stats(pingType, getReferrer())
    .catch(e => {
      console.error("Error when reporting usage statistics", e);
    });
};

export const ctxFunc = {
  ping,
};
