/** @file
 *
 * A proxy for identity provider, that poll from the provided identityProvider
 * if present, then from KaaaS if needed.
 */
import axios from "axios";
import networks from "./networks.js";

const storedResults = {};

/** Create an identity provider with the given settings.
 *
 * @param {function} userProvider
 * @param {bool} useKaaaS
 * @param {string} network
 *
 * @returns {function}
 * The returned function take a publicKey as argument, and return a promise
 * with the details
 */
const identityProviderFactory = (
  userProvider,
  useKaaaS,
  kaaasGroupKey,
  network
) =>
  publicKey => storedResults[publicKey]
    ? Promise.resolve(storedResults[publicKey])
    : (userProvider
      ? userProvider(publicKey)
      : Promise.resolve(null))
      .then(keyInfo => (!keyInfo && useKaaaS)
        ? getFromKaaaS(network, kaaasGroupKey, publicKey)
        : keyInfo)
      .then(keyInfo => {
        if (keyInfo) {
          storedResults[publicKey] = keyInfo;
        }
        return keyInfo;
      });

/** Build the KaaaS identity URL */
const kaaasURL = (network, publicKey, kaaasGroupKey) => {
  const base = networks[network].kaaasURL
    + "/api/v1/signingentity/getIdentityFromKey?publicKey="
    + publicKey;
  if (kaaasGroupKey !== undefined) {
    return `${base}&gk=${kaaasGroupKey}`;
  }
  return base;
};

/** Get identity info from KaaaS
 *
 * @returns {Promise}
 */
const getFromKaaaS = (network, kaaasGroupKey, publicKey) =>
  axios.get(kaaasURL(network, publicKey, kaaasGroupKey))
    .then(res => res.data ?
      ({
        name: res.data.name.en,
        logo: res.data.company_logo_url,
        url: res.data.url,
      })
      : null);

export default identityProviderFactory;
