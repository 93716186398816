/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";
import {
  Circle,
  Marker,
  MapContainer,
  TileLayer,
} from "react-leaflet";
import {
  _l,
  Localize,
} from "../../localize.js";
import Accordion from "@keeex/js-react-components/lib/accordion.js";

import {
  SimpleSection,
  getProp,
  haveRef,
} from "../../common.js";

/** Lookup for categories */
const categoryLookup = {
  ["recov-tycin-mozac-gyzam-mehok-nokyn-gyreb-depal-pabal-"
    + "camul-lokyc-kizib-fimeh-teluz-zihup-mipol-lixyr"]: {
    name: () => _l("Audit"),
    icon: "remove_red_eye",
  },
  ["ruroh-vihed-pokos-vuhek-digan-carak-gemuv-figib-zeryg-"
    + "torep-sicoh-momus-bynuv-niduk-fynyt-sosag-lyxor"]: {
    name: () => _l("Car"),
    icon: "directions_car",
  },
  ["retok-hiduk-hucif-masad-mycip-kusuz-gucim-gafop-monur-"
    + "vedus-zikur-gogev-cynuc-havuv-vupuc-nevar-vuxor"]: {
    name: () => _l("Home"),
    icon: "home",
  },
  ["rivec-hufob-fezot-paceh-myvic-meryc-timen-ticok-syveh-"
    + "purad-vusaf-lupib-pasob-kazuk-gemar-mehas-sexir"]: {
    name: () => _l("Logistics"),
    icon: "local_shipping",
  },
  ["rokoc-rifeb-vetad-hipuh-tyrom-zehuf-medem-zerim-tedyp-"
    + "pyzom-halep-cimok-mekih-repom-giluc-gifih-cyxor"]: {
    name: () => _l("Misc."),
    icon: "local_offer",
  },
};

/** Lookup for source */
const photoRef = {
  photo: "rimas-nikir-pumem-daveh-mives-filir-sotul-nomov-docin-"
          + "gehis-suzyh-gosof-nyzon-cekig-tucup-zepeh-hoxyr",
  pro: "ricaf-zyric-zimas-refyp-rurah-tehyk-nipym-bagac-rerav-"
          + "mubur-kobyh-mutah-rugip-siheb-sidum-sonyb-bexyr",
  mock: "risid-panyf-zasin-fahep-dorym-homih-gimin-dapud-nizef-"
          + "dyfob-lydyg-capop-deler-reduz-petyr-hahev-hexor",
};

/** Convert latitude/longitude/accuracy to a string */
const coordinateToString = (position, accuracy) => {
  const lat = Math.floor(position.latitude * 1000) / 1000;
  const lon = Math.floor(position.longitude * 1000) / 1000;
  const acc = Math.floor(accuracy * 10) / 10;
  return <Localize
    data-lat={lat}
    data-lon={lon}
    data-acc={acc}> %%%lat%%%,%%%lon%%% (accuracy: %%%acc%%%m)</Localize>;
};

class Photoproof extends React.Component {
  constructor(props) {
    super(props);
    this._status =
      getProp(this.props.result, "photoproof.location.status");
    this._latitude = parseFloat(
      getProp(this.props.result, "photoproof.location.latitude"));
    this._longitude = parseFloat(
      getProp(this.props.result, "photoproof.location.longitude"));
    this._accuracy = parseFloat(
      getProp(this.props.result, "photoproof.location.accuracy"));
    this._map;
  }

  becomeVisible() {
    if (this._map) {
      this._map.invalidateSize(false);
    }
  }

  _haveCoords() {
    return ["OK", "MOCK"].includes(this._status);
  }

  _isMock() {
    return haveRef(this.props.result, photoRef.mock);
  }

  _renderMap() {
    if (!this._haveCoords()) {
      return undefined;
    }
    const position = [
      this._latitude,
      this._longitude,
    ];
    const fillColor = this._isMock()
      ? "red"
      : "blue";
    return <SimpleSection
      title={<Localize>Map</Localize>}
      className="geoMap">
      <MapContainer
        whenCreated={map => this._map = map}
        center={position}
        zoom={13}>
        <TileLayer
          attribution={
            "données &copy; <a href=\"https://osm.org/copyright\">"
                + "OpenStreetMap</a>/ODbL - rendu <a href="
                + "\"https://openstreetmap.fr\">OSM France</a>"}
          url="https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png" />
        <Marker position={position} />
        <Circle
          center={position}
          fillColor={fillColor}
          radius={this._accuracy} />
      </MapContainer>
    </SimpleSection>;
  }

  _renderCoordinates() {
    const classes = ["geoCoordinates"];
    if (this._isMock()) {
      classes.push("mockCoordinates");
    }
    const position = {
      latitude: this._latitude,
      longitude: this._longitude,
    };
    return <SimpleSection
      title={<Localize>Geographic coordinates</Localize>}
      className={classes.join(" ")}>
      {this._isMock()
        ? <React.Fragment>
          <span className="material-icons is-pulled-left">
            warning
          </span>&nbsp;
          {<Localize>Possibly fake coordinates</Localize>}
        </React.Fragment>
        : this._haveCoords()
          ? <Localize>Coordinates from the phone</Localize>
          : undefined}
      <br />
      {this._haveCoords()
        ? coordinateToString(position, this._accuracy)
        : <Localize>No coordinates found</Localize>}
    </SimpleSection>;
  }

  _renderCategory() {
    const categories = [];
    Object.keys(categoryLookup).forEach(catIdr => {
      if (!haveRef(this.props.result, catIdr)) {
        return;
      }
      categories.push(categoryLookup[catIdr]);
    });
    if (categories.length == 0) {
      return;
    }
    return <SimpleSection
      title={<Localize>Category</Localize>}
      className="geoCategory">
      <span className="material-icons">{categories[0].icon}</span>&nbsp;
      <span>{categories[0].name()}</span>
    </SimpleSection>;
  }

  render() {
    return <React.Fragment>
      {this._renderMap()}
      {this._renderCoordinates()}
      {this._renderCategory()}
    </React.Fragment>;
  }
}
Photoproof.propTypes = {
  result: PropTypes.object,
};

const render = (result) => {
  if (!haveRef(result, photoRef.photo)) {
    return;
  }
  const mapRef = React.createRef();
  return <Accordion.Section
    title={<Localize>Photo Proof by KeeeX details</Localize>}
    onOpen={() => mapRef.current.becomeVisible()}
    className="infoRows photoproof">
    <Photoproof
      ref={mapRef}
      result={result} />
  </Accordion.Section>;
};

export default render;
