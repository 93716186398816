import createContext from "@cley_faye/react-utils/lib/context/state.js";
import {ctxFunc} from "../services/stats.js";

export default createContext(
  "Stats",
  {
    apiKey: null,
  },
  ctxFunc
);
