import React from "react";
import {Localize, _l} from "../../localize.js";

import Accordion from "@keeex/js-react-components/lib/accordion.js";

import {SimpleSection} from "../../common.js";
import renderBlockchain from "./blockchain.js";
import renderRFC3161 from "./rfc3161.js";
import {
  BLOCKCHAIN_ICON,
  RFC3161_UNAVAILABLE,
  RFC3161_LOADING,
  RFC3161_OK,
  STATUS_UNAVAILABLE,
  STATUS_LOADING,
  STATUS_OK,
  makeIcon,
} from "./icon.js";
import { Tooltip } from "../../tooltip.js";

const _renderTimestamp = (
  network,
  timestampInfo,
  timestampError
) => {
  return <React.Fragment>
    <SimpleSection
      title={<Localize>Blockchain anchoring</Localize>}
      className="blockchainTimestamp">
      {renderBlockchain(network, timestampInfo, timestampError)}
    </SimpleSection>
    <SimpleSection
      title={<Localize>Timestamping authority</Localize>}
      className="rfc3161Timestamp">
      {renderRFC3161(timestampInfo, timestampError)}
    </SimpleSection>
  </React.Fragment>;
};

const accordionTitle = (
  timestampInfo,
  timestampError
) => {
  const icons = [];
  if (!timestampInfo || timestampError) {
    icons.push([STATUS_UNAVAILABLE, BLOCKCHAIN_ICON]);
  } else {
    if (timestampInfo.loading) {
      icons.push([STATUS_LOADING, BLOCKCHAIN_ICON]);
    } else if (timestampInfo.topicIdx) {
      icons.push([STATUS_OK, BLOCKCHAIN_ICON]);
    } else {
      icons.push([STATUS_UNAVAILABLE, BLOCKCHAIN_ICON]);
    }
  }
  const tsrInfo = timestampInfo && timestampInfo.tsr;
  if (!tsrInfo || timestampError) {
    icons.push([STATUS_UNAVAILABLE, RFC3161_UNAVAILABLE]);
  } else {
    if (tsrInfo.loading) {
      icons.push([STATUS_LOADING, RFC3161_LOADING]);
    } else if (timestampInfo.tsaUrl) {
      icons.push([STATUS_OK, RFC3161_OK]);
    } else {
      icons.push([STATUS_UNAVAILABLE, RFC3161_UNAVAILABLE]);
    }
  }
  const iconsElem = icons.map(iconDef =>
    makeIcon(iconDef[0], iconDef[1], iconDef[1]));
  return <React.Fragment>
    <Localize>Timestamp information</Localize>
    {iconsElem}
    {/* eslint-disable-next-line max-len */}
    <Tooltip text={_l("In addition to the keeexing process, the file can be timestamped via a timestamp server and anchored on one or more blockchains to prove its existence at a given date. The document and the identity of the signatories are never shared and remain confidential, only the digital fingerprint of the file is sent to these services.")} />
  </React.Fragment>;
};

const render = (
  network,
  timestampInfo,
  timestampError
) => {
  return <Accordion.Section
    title={accordionTitle(timestampInfo, timestampError)}
    className="infoRows">
    {_renderTimestamp(
      network,
      timestampInfo,
      timestampError)}
  </Accordion.Section>;
};

export default render;
