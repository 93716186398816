import React from "react";
import PropTypes from "prop-types";
import {
  _l,
  Localize,
} from "./localize.js";

import IDX from "./idx.js";

import {
  SimpleSection,
  renderIdentities,
  getIDX,
  getProp,
  PreciseDate,
} from "./common.js";
import { Tooltip } from "./tooltip.js";

/**
 * Props:
 * - identityProvider
 * - result: kxresult
 * - expanded
 * - onReset
 * - onExpand
 */
export default class Simple extends React.Component {
  _renderStatus() {
    const statuses = {
      // Virtual state injected by Verify for BTC certificates
      "BTC_CERTIFICATE": [_l("KeeeX blockchain anchoring certificate"),
        "beenhere", "is-valid"],
      "PROTECTED": [_l("Unmodified file"), "check_circle", "is-valid"],
      "VALID": [
        _l("File is valid but contains no signature"),
        "error_outline",
        "is-warning"],
      "NOKEEEX": [_l("File not keeexed"), "error_outline", "is-error"],
      "INVALID_DS": [_l("Invalid signature"), "error", "is-error"],
      "INVALID_FIDO_DS": [_l("Invalid signature"), "error", "is-error"],
      "INVALID_IDX": [_l("Altered file"), "error", "is-error"],
      "MISSING_SIGNATURE": [
        _l("Missing some signatures"),
        "error_outline",
        "is-warning"],
      "UNKNOWN": [_l("Unknown state"), "error", "is-error"],
      // Virtual state set in the Verify component
      "NOT_ALLOWED": [_l("File from an unknown source"), "error", "is-error"],
    };
    const value = statuses[this.props.result.state]
      || statuses["UNKNOWN"];
    const classes = ["status", "has-text-centered", value[2]];
    return <div className={classes.join(" ")}>
      <span className="statusIcon material-icons">{value[1]}</span>
      <span className="statusLabel"><Localize>{value[0]}</Localize></span>
    </div>;
  }

  _renderName() {
    if (this.props.result.name) {
      return <div className="filename">{this.props.result.name}</div>;
    }
  }

  _renderDetails() {
    const idx = getIDX(this.props.result);
    if (!idx) {
      return;
    }
    return <SimpleSection
      title={<>
        <Localize>Unique file identifier (IDX)</Localize>
        {/* eslint-disable-next-line max-len */}
        <Tooltip text={_l("A unique digital fingerprint (or hash) of the file that allows a file to be uniquely identified without revealing its contents and thus proving its integrity. KeeeX encodes it in a humanized, easily pronounceable and indexable on the Internet and your computer.")} />
      </>}
      className="fileIDX">
      <IDX
        limit={4}
        idx={idx}
        showTooltip
        copyOnClick />
    </SimpleSection>;
  }

  _renderDate() {
    const date = getProp(this.props.result, "kx.time");
    return <PreciseDate
      tsrResult={this.props.timestampInfo && this.props.timestampInfo.tsr}
      systemDate={date} />;
  }

  _renderDetailButton() {
    if (!getIDX(this.props.result)) {
      return;
    }
    return <button
      className="button"
      onClick={() => this.props.onExpand()}>
      <Localize>Details</Localize>
    </button>;
  }

  render() {
    return <div className="result">
      {this._renderStatus()}
      {this._renderName()}
      <div className="infoRows">
        {renderIdentities(
          getIDX(this.props.result),
          this.props.result.identities,
          this.props.identityProvider,
          true)}
        {this._renderDetails()}
        {this._renderDate()}
      </div>
      {this._renderDetailButton()}
      <a
        className="reset"
        onClick={this.props.onReset}>
        <span className="material-icons">replay</span>
        <Localize>New verification</Localize>
      </a>
    </div>;
  }
}
Simple.propTypes = {
  result: PropTypes.object,
  expanded: PropTypes.bool,
  onReset: PropTypes.func,
  onExpand: PropTypes.func,
  timestampInfo: PropTypes.object,
  identityProvider: PropTypes.func,
};
