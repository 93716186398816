import {
  genLFunction,
  genLocalizeClass,
  LocaleUpdate,
  LocalizeContext,
} from "@keeex/js-localize";

const localizePrefix = "@keeex/js-verify";

export const _L = genLFunction(localizePrefix);
export const _l = str => str;
export const Localize = genLocalizeClass(localizePrefix);

export {
  LocalizeContext,
  LocaleUpdate,
};
