/*eslint-env browser */
import React from "react";
import PropTypes from "prop-types";
import {KeeexJS} from "@keeex/jssdk";
import {Localize} from "./localize.js";

import Dropzone from "./dropzone.js";
import SimpleResult from "./simple.js";
import ComplexResult from "./complex.js";
import {
  getIDX,
  getAsBitcoinCertificate,
} from "./common.js";
import networks from "./networks.js";
import identityProviderFactory from "./identityprovider.js";
import StaticCtx from "./context/static.js";
import StatsCtx from "./context/stats.js";
import {ping, PingTypes} from "./services/stats.js";

/** Verify a file object in the browser.
 *
 * @param {File} file
 *
 * @returns {Promise}
 */
const verifyFile = (kxsdk, file) => new Promise(resolve => {
  const reader = new FileReader();
  reader.onload = e => resolve(kxsdk.verifyDocument(
    file.name,
    e.target.result));
  reader.readAsArrayBuffer(file);
});

/** Verify a file from its base64 content
 *
 * @param {string} name
 * @param {string} b64Content
 *
 * @returns {Promise}
 */
const verifyB64File = (kxsdk, name, b64Content) =>
  kxsdk.verifyDocument(
    name,
    Uint8Array.from(atob(b64Content), c => c.charCodeAt(0)).buffer);

/** Verify a file from a buffer
 *
 * @param {string} name
 * @param {Buffer} content
 *
 * @returns {Promise}
 */
const verifyBufferFile = (kxsdk, name, content) =>
  kxsdk.verifyDocument(
    name,
    content);

const VerifyEvents = {
  ready: "ready",
  readFile: "readFile",
  fileValid: "fileValid",
  fileFail: "fileFail",
  openDetails: "openDetails",
};

/**
 * Props:
 * - identityProvider(function): Function called with a public key that return
 *   a promise. The promise can resolve with an identity, which is an object
 *   with the following properties:
 *   - name
 *   - logo
 *   - url
 * - restrictToProvidedIdentities(bool): only files that have at least one
 *   known identity will be verified.
 * - useKaaaS(bool): use KaaaS as an alternative identity provider
 */
export default class Verify extends React.Component {
  constructor(props) {
    super(props);
    this.kxsdk = new KeeexJS({
      env: networks[this.props.network].kxsdkEnv,
    });
    // TODO Cleaner way to handle the two-stage loading of timestamp/TSR status
    // (for example, merge them in a single object)
    this.state = Object.assign({
      result: undefined,
      timestampInfo: {loading: true},
      timestampError: "",
      showDetails: false,
      fileCounter: 0,
    }, this.state || {});
    StatsCtx.init(
      this,
      {
        apiKey: props.statsKey,
      }
    );
    if (window.chl) {
      const old = window.chl;
      window.vrf = this;
      window.chl = loc => {
        old(loc).then(() => this.forceUpdate());
      };
    }
    this._dropRef = React.createRef();
    this._updateTimestamp = this._updateTimestamp.bind(this);
    this.resetView = this.resetView.bind(this);
  }

  componentDidMount() {
    ping(this.state.StatsCtx, PingTypes.display);
    this.doEvent(VerifyEvents.ready);
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.statsKey !== this.props.statsKey) {
      this.setState({StatsCtx: {apiKey: this.props.statsKey}});
    }
    if (oldState.showDetails !== this.state.showDetails) {
      this.doEvent(VerifyEvents.openDetails, this.state.showDetails);
    }
  }

  doEvent(event, data) {
    if (this.props.onVerifyEvent) {
      this.props.onVerifyEvent(event, data);
    }
  }

  _updateTimestamp() {
    if (!this.state.result) {
      return;
    }
    const certificateInfo = getAsBitcoinCertificate(this.state.result);
    if (certificateInfo) {
      const result = this.state.result;
      result.state = "BTC_CERTIFICATE";
      this.setState({
        result,
        timestampInfo: certificateInfo.timestampInfo,
        timestampError: null,
      });
      return;
    }
    const idx = getIDX(this.state.result);
    if (idx) {
      this.kxsdk.getTimestampInfo(idx, true)
        .then(timestampInfo => {
          if (!timestampInfo) {
            this.setState({
              timestampInfo: {loading: false},
              timestampError: null,
            });
            return;
          }
          this.setState({timestampInfo});
        }).catch(timestampError => this.setState({
          timestampInfo: {loading: false},
          timestampError,
        })
        );
    } else {
      this.setState({
        timestampInfo: {loading: false},
        timestampError: new Error("No IDX"),
      });
    }
  }

  _onDrop(file) {
    ping(this.state.StatsCtx, PingTypes.filesProcessed);
    this.doEvent(VerifyEvents.readFile, file);
    verifyFile(this.kxsdk, file)
      .then(result => {
        if (window.chl) {
          window.kxRes = result;
        }
        return result;
      }).then(result => {
        if (!this.props.restrictToProvidedIdentities) {
          return result;
        }
        if (result.identities) {
          let allowed = false;
          const proxy = this.proxyIdentityProvider();
          const promises = result.identities.map(identity => {
            return proxy(identity.publicKey)
              .then(identityData => {
                if (identityData) {
                  allowed = true;
                }
              });
          });
          return Promise.all(promises)
            .then(() => allowed
              ? result
              : {
                state: "NOT_ALLOWED",
              });
        }
        return {
          state: "NOT_ALLOWED",
        };
      }).then(result => {
        this.pingResult(result);
        this.eventResult(result);
        this.setState(oldState => ({
          result,
          showDetails: false,
          timestampInfo: {loading: true},
          timestampError: null,
          fileCounter: oldState.fileCounter + 1,
        }), this._updateTimestamp);
      });
  }

  eventResult(result) {
    if (result.state === "PROTECTED") {
      this.doEvent(VerifyEvents.fileValid, result.mainIdx);
      return;
    }
    this.doEvent(VerifyEvents.fileFail, result.mainIdx);
  }

  pingResult(result) {
    let type;
    if (result.state === "PROTECTED") {
      type = PingTypes.filesValid;
    } else if (result.state === "NOKEEEX") {
      type = PingTypes.filesNonKeeex;
    }
    if (type) {
      ping(this.state.StatsCtx, type);
    }
  }

  /** Simulate a drop.
   *
   * @param {hash} file
   * An object with the following properties:
   * - name: file name
   * - base64: the base64 content of the file (or buffer)
   * - buffer: the buffer containing the file (or base64)
   *
   * If both base64 and buffer are defined, buffer is used.
   */
  fakeDrop(file) {
    return (file.buffer
      ? verifyBufferFile(
        this.kxsdk,
        file.name,
        file.buffer)
      : verifyB64File(
        this.kxsdk,
        file.name,
        file.base64))
      .then(result => this.setState(oldState => ({
        result,
        showDetails: false,
        timestampInfo: {loading: true},
        timestampError: null,
        fileCounter: oldState.fileCounter + 1,
      }), this._updateTimestamp));
  }

  proxyIdentityProvider() {
    return identityProviderFactory(
      this.props.identityProvider,
      this.props.useKaaaS,
      this.props.kaaasGroupKey,
      this.props.network);
  }

  resetView() {
    this.setState({result: undefined}, () => {
      if (this._dropRef.current) {
        this._dropRef.current.open();
      }
      this.doEvent(VerifyEvents.ready);
    });
  }

  render() {
    let leftPane;
    let rightPane;
    if (this.state.result === null) {
      leftPane = <p><Localize>An error occured…</Localize></p>;
    } else if (this.state.result) {
      leftPane = <SimpleResult
        key={this.state.fileCounter}
        identityProvider={this.proxyIdentityProvider()}
        result={this.state.result}
        onExpand={() => this.setState(oldState => (
          {showDetails: !oldState.showDetails}))}
        onReset={this.resetView}
        timestampInfo={this.state.timestampInfo}
        expanded={this.state.showDetails} />;
      if (this.state.showDetails) {
        rightPane = <ComplexResult
          key={this.state.fileCounter}
          timestampInfo={this.state.timestampInfo}
          timestampError={this.state.timestampError}
          identityProvider={this.proxyIdentityProvider()}
          network={this.props.network}
          staticPath={this.props.staticPath}
          result={this.state.result} />;
      }
    }
    const rightPaneWithSpan = rightPane
      ? <span className="rightPane">{rightPane}</span>
      : undefined;
    return <StaticCtx.Provider value={{staticPath: this.props.staticPath}}>
      <StatsCtx.Provider stateRef={this}>
        <div className="keeex-verifier">
          <span className="leftPane box">
            <Dropzone
              ref={this._dropRef}
              onDrop={file => this._onDrop(file)}
              visible={this.state.result === undefined} />
            {leftPane}
          </span>
          {rightPaneWithSpan}
        </div>
      </StatsCtx.Provider>
    </StaticCtx.Provider>;
  }
}
Verify.propTypes = {
  identityProvider: PropTypes.func,
  restrictToProvidedIdentities: PropTypes.bool,
  staticPath: PropTypes.string,
  useKaaaS: PropTypes.bool,
  kaaasGroupKey: PropTypes.string,
  statsKey: PropTypes.string,
  network: PropTypes.oneOf(["beta", "prod"]),
  onVerifyEvent: PropTypes.func,
};
Verify.defaultProps = {
  useKaaaS: false,
  statsKey: undefined,
  kaaasGroupKey: undefined,
  network: "prod",
  onVerifyEvent: undefined,
};
