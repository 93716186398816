/*eslint-env browser, commonjs */
import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import Verifier from "./index.js";

import {
  LocalizeContext,
  addLocale,
  setLocale,
} from "@keeex/js-localize";
import langEN from "../langs/en.json";
import langFR from "../langs/fr.json";
import moment from "moment";

class VerifierLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: this.props.locale,
    };
  }

  setLocale(locale) {
    this.setState({locale});
  }

  render() {
    const kaaasSettings = this.props.kaaasGroupKey
      ? {useKaaaS: true, kaaasGroupKey: this.props.kaaasGroupKey}
      : undefined;
    return <LocalizeContext.Provider value={this.state}>
      <Verifier
        network="prod"
        staticPath={this.props.staticPath}
        identityProvider={this.props.identityProvider}
        restrictToProvidedIdentities={this.props.restrictToProvidedIdentities}
        onVerifyEvent={this.props.onVerifyEvent}
        {...kaaasSettings}
      />
    </LocalizeContext.Provider>;
  }
}
VerifierLoader.propTypes = {
  staticPath: PropTypes.string,
  identityProvider: PropTypes.func,
  locale: PropTypes.string,
  restrictToProvidedIdentities: PropTypes.bool,
  kaaasGroupKey: PropTypes.string,
  onVerifyEvent: PropTypes.func,
};
VerifierLoader.defaultProps = {
  identityProvider: undefined,
  restrictToProvidedIdentities: false,
  kaaasGroupKey: undefined,
  onVerifyEvent: undefined,
};

let isFirstLoad = true;

const firstLoad = () => {
  if (!isFirstLoad) {
    return;
  }
  isFirstLoad = true;
  addLocale("en", langEN);
  addLocale("fr", langFR);
};


export const loadKeeexVerifier = (config = {}) => {
  const {locale, domParent, staticPath} = config;
  const verifRef = React.createRef();
  firstLoad(verifRef);
  moment.locale(locale || "en");
  ReactDOM.render(
    <VerifierLoader
      ref={verifRef}
      locale={locale || "en"}
      staticPath={staticPath || "."}
      identityProvider={config.identityProvider}
      restrictToProvidedIdentities={config.restrictToProvidedIdentities}
      kaaasGroupKey={config.kaaasGroupKey}
      onVerifyEvent={config.onVerifyEvent}
    />,
    domParent || document.getElementById("keeexVerifier"));
  return {
    setLocale: loc => {
      moment.locale(loc);
      return setLocale(loc)
        .then(() => verifRef.current.setLocale(loc));
    },
  };
};

