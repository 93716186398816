/*eslint-env browser */
import React from "react";
import PropTypes from "prop-types";
import ReactDropzone from "react-dropzone";
import {Localize} from "./localize.js";

export default class Dropzone extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({
      full: false,
    }, this.state || {});
    // Done this way to enable the removeListener call
    this._endDragFunc = () => {
      this.setState({full: false});
    };
    this._startDragFunc = () => {
      this.setState({full: true});
    };
    this._dropzoneRef = React.createRef();
  }

  _onDrop(files) {
    this._endDragFunc();
    const newFile = (files && files.length) ? files[0] : null;
    if (!newFile) {
      return;
    }
    this.props.onDrop(newFile);
  }

  componentDidMount() {
    window.addEventListener("dragenter", this._startDragFunc);
  }

  componentWillUnmount() {
    window.removeEventListener("dragenter", this._startDragFunc);
  }

  _doClick() {
    this.open();
  }

  _renderVisual() {
    if (!this.props.visible) {
      return undefined;
    }
    return <div
      className="visualZone"
      onClick={() => this._doClick()}>
      <p>
        <span className="dropIcon material-icons">add_circle_outline</span>
      </p>
      <p className="tabletPlus">
        <Localize
        >Click or Drag &amp; Drop a file to verify its authenticity</Localize>
      </p>
      <p className="mobileOnly">
        <Localize
        >Click here to verify the authenticity of a file</Localize>
      </p>
    </div>;
  }

  /** Open the file prompt dialog.
   *
   * This will most likely only work in user-triggered calls
   */
  open() {
    this._dropzoneRef.current.open();
  }

  render() {
    const classes = ["dropzone"];
    if (this.state.full) {
      classes.push("full");
    }
    const RDZ = (typeof ReactDropzone.default !== "undefined")
      ? ReactDropzone.default
      : ReactDropzone;
    return <React.Fragment>
      <RDZ
        onClick={e => e.preventDefault()}
        onDrop={files => this._onDrop(files)}
        ref={this._dropzoneRef}>
        {({getRootProps, getInputProps}) => <div
          {...getRootProps()}
          onDragLeave={this._endDragFunc}
          className={classes.join(" ")}>
          <input {...getInputProps()} />
        </div>}
      </RDZ>
      {this._renderVisual()}
    </React.Fragment>;
  }
}
Dropzone.propTypes = {
  onDrop: PropTypes.func,
  visible: PropTypes.bool,
};
