import React from "react";

export const BLOCKCHAIN_ICON = "timeline";
export const RFC3161_UNAVAILABLE = "assignment_late";
export const RFC3161_LOADING = "assignment";
export const RFC3161_OK = "assignment_turned_in";
export const STATUS_UNAVAILABLE = "unavailable";
export const STATUS_LOADING = "loading";
export const STATUS_OK = "ok";

export const makeIcon = (iconStatus, iconName, key) =>
  <span
    key={key}
    className={`icon-status icon-status-${iconStatus} material-icons`}>
    {iconName}
  </span>;
