import React from "react";
import {Localize, _l} from "../../localize.js";

import {SimpleSection} from "../../common.js";
import networks from "../../networks.js";
import {
  BLOCKCHAIN_ICON,
  STATUS_OK,
  makeIcon,
} from "./icon.js";
import { Tooltip } from "../../tooltip.js";

const _renderBTCCertificate = timestampInfo => {
  if (timestampInfo.isCertificate) {
    return null;
  }
  const value = timestampInfo.downloadUrl
    ? <React.Fragment>
      <a
        href={timestampInfo.downloadUrl}
        rel="noopener noreferrer"
        target="_blank">
        {<Localize>Download</Localize>}
      </a>
      &emsp;—&emsp;
      <a
        href={timestampInfo.url}
        rel="noopener noreferrer"
        target="_blank">
        {<Localize>View</Localize>}
      </a>
    </React.Fragment>
    : <span>
      {<Localize>This file is being timestamped.</Localize>}
      {<Localize>This operation can take at most 12 hours.</Localize>}
    </span>;
  return <SimpleSection
    title={<React.Fragment>
      <Localize>Bitcoin certificate</Localize>
      {makeIcon(STATUS_OK, BLOCKCHAIN_ICON)}
      {/* eslint-disable-next-line max-len */}
      <Tooltip text={_l("A downloadable keeexed file that gathers all the anchoring requests from our different customers over a given period. The unique digital fingerprint of this file is registered on a blockchain through a transaction; this mutualization allows to reduce the carbon impact and to guarantee scalability and anonymity of our users.")} />
    </React.Fragment>}
    className="btcCertificate">
    {value}
  </SimpleSection>;
};

const _renderBTCTransaction = (network, timestampInfo) => {
  if (!timestampInfo.transactionId) {
    return;
  }
  return <SimpleSection
    title={<>
      <Localize>Bitcoin transaction Id</Localize>
      {/* eslint-disable-next-line max-len */}
      <Tooltip text={_l("Transaction identifier corresponding to the anchoring (direct or shared) of the file on the Bitcoin blockchain.")} />
    </>}
    className="btcTransaction">
    <a
      className="transactionHash"
      href={networks[network].btcExplorer(timestampInfo.transactionId)}
      target="_blank"
      rel="noreferrer noopener">
      {timestampInfo.transactionId}
    </a>
  </SimpleSection>;
};

/** kwak */
const _renderBitcoincoin = (network, timestampInfo) => {
  if (timestampInfo.downloadUrl
      || timestampInfo.transactionId
      || !timestampInfo.ethTransactionHash) {
    /*eslint-disable max-len */
    return <React.Fragment>
      <div className="infoRow information">
        {timestampInfo.isCertificate
          ? <Localize>
              A KeeeX blockchain anchoring certificate contains the identifiers
              (IDX) of anchored files in a given period. Its hash is
              recorded on a blockchain, providing a proof of existence of the
              certificate at a given date and as such, proof of existence of
              all referenced files.
          </Localize>
          : <Localize>
              KeeeX blockchain anchoring certificate contains the identifier
              (IDX) of your file. Its hash is recorded on a blockchain,
              providing a proof of existence of the file on a given date.
          </Localize>}
      </div>
      {_renderBTCCertificate(timestampInfo)}
      {_renderBTCTransaction(network, timestampInfo)}
    </React.Fragment>;
    /*eslint-enable max-len */
  }
};

const _renderEthereum = (network, timestampInfo) => {
  if (!timestampInfo.ethTransactionHash) {
    return;
  }
  return <React.Fragment>
    <SimpleSection
      title={<>
        <Localize>Ethereum transaction fingerprint</Localize>
        {/* eslint-disable-next-line max-len */}
        <Tooltip text={_l("Transaction identifier corresponding to the anchoring (direct or shared) of the file on the Ethereum blockchain.")} />
      </>}
      className="ethTransaction">
      <a
        className="transactionHash"
        href={networks[network].ethExplorer(timestampInfo.ethTransactionHash)}
        target="_blank"
        rel="noreferrer noopener">
        {timestampInfo.ethTransactionHash}
      </a>
    </SimpleSection>
  </React.Fragment>;
};

const _renderBlockchain = (network, timestampInfo, timestampError) => {
  if (timestampError) {
    return <p className="infoRow">
      An error occured; please try again later.
    </p>;
  }
  if (timestampInfo.loading) {
    return <p className="infoRow">Please wait while we retrieve data…</p>;
  }
  if (!timestampInfo.topicIdx) {
    return <p className="infoRow">
      <Localize>This file has no timestamping information.</Localize>
    </p>;
  }
  return <React.Fragment>
    {_renderBitcoincoin(network, timestampInfo)}
    {_renderEthereum(network, timestampInfo)}
  </React.Fragment>;
};

const render = (network, timestampInfo, timestampError) => {
  return _renderBlockchain(network, timestampInfo, timestampError);
};

export default render;
