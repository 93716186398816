import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import {_L} from "./localize.js";

export class Tooltip extends React.Component {
  render() {
    return <span
      className={clsx(
        "infoTip",
        "material-icons",
        `has-tooltip-${this.props.position}`,
        "has-tooltip-multiline"
      )}
      data-tooltip={_L(this.props.text)}
    >info</span>;
  }
}
Tooltip.displayName = "Tooltip";
Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  position: PropTypes.oneOf(["top", "bottom"]),
};
Tooltip.defaultProps = {
  position: "top",
};
