/*eslint-env browser */
import React from "react";
import PropTypes from "prop-types";
import {Localize, _l} from "../../localize.js";

import {
  SimpleSection,
  TSRDate,
} from "../../common.js";
import {
  RFC3161_OK,
  STATUS_OK,
  makeIcon,
} from "./icon.js";
import { Tooltip } from "../../tooltip.js";

class Timestamp extends React.Component {
  _renderSource() {
    if (!this.props.timestampInfo.tsr.source) {
      return <span>
        <Localize
        >Authority name can not be extracted from certificate</Localize>
      </span>;
    }
    return <span>{this.props.timestampInfo.tsr.source}</span>;
  }

  render() {
    if (!this.props.timestampInfo.tsaUrl) {
      return <div className="infoRow">
        <Localize>No RFC3161 certificate found.</Localize>
      </div>;
    }
    const idx = this.props.timestampInfo.topicIdx;
    return <React.Fragment>
      <TSRDate
        tsrData={this.props.timestampInfo.tsr}
        extended />
      <SimpleSection
        title={<Localize>Timestamping system</Localize>}
        className="rfcSource">
        {this._renderSource()}
      </SimpleSection>
      <SimpleSection
        title={<React.Fragment>
          <Localize>RFC3161 Certificate</Localize>
          {makeIcon(STATUS_OK, RFC3161_OK)}
          {/* eslint-disable-next-line max-len */}
          <Tooltip text={_l("A downloadable file in tsr format that can be used to certify the existence of a file at a given date. Technically, the unique fingerprint of the file is signed by a timestamping server.")} />
        </React.Fragment>}
        className="rfcDownload">
        <a
          href={this.props.timestampInfo.tsaUrl}
          target="_blank"
          rel="noopener noreferrer">
          {<Localize>Download</Localize>}
        </a>
        &emsp;—&emsp;
        <a
          href={`https://services.keeex.me/timestamps/idx/#idx=${idx}`}
          target="_blank"
          rel="noopener noreferrer">
          {<Localize>View details</Localize>}
        </a>
      </SimpleSection>
    </React.Fragment>;
  }
}
Timestamp.propTypes = {
  timestampInfo: PropTypes.object,
};

const _renderTimestamp =
  (timestampInfo, timestampError) => {
    if (timestampError) {
      return <div className="infoRow">
        <Localize>An error occured; please try again later.</Localize>
      </div>;
    }
    if (timestampInfo === undefined) {
      return <div className="infoRow">
        <Localize>Please wait while we retrieve data…</Localize>
      </div>;
    }
    if (timestampInfo === null) {
      return <div className="infoRow">
        <Localize>This file has no timestamping information.</Localize>
      </div>;
    }
    return <Timestamp timestampInfo={timestampInfo} />;
  };

const render = (timestampInfo, timestampError) => {
  return timestampInfo.isCertificate
    ? null
    : _renderTimestamp(timestampInfo, timestampError);
};

export default render;
