import React from "react";
import PropTypes from "prop-types";
import ClipboardJS from "clipboard";
import {toast} from "bulma-toast";
import {head as idxHead} from "@keeex/js-utils/lib/idx.js";
import {_L} from "./localize.js";

let uniqueId = 0;

/** Return a unique ID (for this session) */
export const getUniqueId = () => `keeexVerifIDX${++uniqueId}`;

/**
 * Props:
 * - className
 * - idx(string)
 * - extended(bool)
 * - canExtend(bool)
 * - showTooltip(bool)
 * - copyOnClick(bool)
 * - limit(integer) number of words to initially display
 *   Default to 3
 */
export default class IDX extends React.Component {
  constructor(props) {
    super(props);
    this.state = Object.assign({
      extended: false,
    }, this.state || {});
    this._id = getUniqueId();
  }

  _isExtended() {
    return this.state.extended || this.props.extended;
  }

  _renderContent() {
    return !this.props.idx
      ? "-"
      : this._isExtended()
        ? this.props.idx
        : idxHead(this.props.idx, this.props.limit || 3);
  }

  _doClick() {
    if (this.props.canExtend) {
      this.setState({extended: true});
    }
  }

  componentDidMount() {
    if (this.props.copyOnClick) {
      const clipboard = new ClipboardJS(`#${this._id}`, {
        text: () => this.props.idx,
      });
      clipboard.on("success", () => {
        toast({
          message: _L("IDX copied to clipboard"),
          type: "is-info",
          pauseOnHover: true,
        });
      });
      clipboard.on("error", () => this.setState({extended: true}));
    }
  }

  render() {
    const classes = ["idx"];
    if (this.props.className) {
      classes.push(this.props.className);
    }
    if (this._isExtended()) {
      classes.push("extended");
    }
    let tooltipProps = {};
    if (this.props.showTooltip && !this.props.extended) {
      classes.push("has-tooltip-top");
      classes.push("has-tooltip-multiline");
      tooltipProps = {
        "data-tooltip": this.props.idx,
      };
    }
    return <span
      id={this._id}
      className={classes.join(" ")}
      onClick={() => this._doClick()}
      {...tooltipProps}>
      {this._renderContent()}
    </span>;
  }
}
IDX.propTypes = {
  idx: PropTypes.string,
  limit: PropTypes.number,
  extended: PropTypes.bool,
  canExtend: PropTypes.bool,
  className: PropTypes.string,
  showTooltip: PropTypes.bool,
  copyOnClick: PropTypes.bool,
};
