/** @file
 *
 * Configuration for different networks.
 *
 * Supported networks:
 * - beta
 * - prod
 *
 * Configuration features:
 * - KXSDK network configuration
 * - bitcoin explorer link
 * - ethereum explorer link
 * - kaaas identity provider
 */
const beta = {
  kxsdkEnv: "qa",
  btcExplorer: transaction =>
    `https://live.blockcypher.com/btc-testnet/tx/${transaction}`,
  ethExplorer: transaction =>
    `https://ropsten.etherscan.io/tx/${transaction}`,
  kaaasURL: "https://kaaas.clients.qa.keeex.me",
};

const prod = {
  kxsdkEnv: "prod",
  btcExplorer: transaction =>
    `https://www.blockchain.com/btc/tx/${transaction}`,
  ethExplorer: transaction =>
    `https://blockscout.com/etc/mainnet/tx/${transaction}`,
  kaaasURL: "https://kaaas.keeex.me",
};

export default {
  beta,
  prod,
};
